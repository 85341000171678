import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  allData: [],
  addData: {},
  sorted: [],
  otpStatus: {},
};

const slice = createSlice({
  name: 'Coupon',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    getCouponSuccess(state, action) {
      state.data = action.payload.data;
    },

    otpSentSuccess(state, action) {
      state.otpStatus = action.payload.data;
    },
    getAllCouponSuccess(state, action) {
      state.allData = action.payload.data;
    },

    addOrUpdateCoupon(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getAllCoupon() {
  return async () => {
    try {
      const response = await get(`allCoupon`);
      if (response.status) {
        dispatch(slice.actions.getAllCouponSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCoupon(locationId, type, getGlobal = false, validateStatus = false, status = false) {
  return async () => {
    try {
      const response = await get(`Coupon?locationId=${locationId}&type=${type}&getGlobal=${getGlobal}&validateStatus=${validateStatus}&status=${status}`);
      if (response.status) {
        dispatch(slice.actions.getCouponSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function sendOTPForCoupon(body) {
  return async () => {
    try {
      const response = await post(`sendOTPForCoupon`, body);
      if (response.status) {
        dispatch(slice.actions.otpSentSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearOtpStatus() {
  return async () => {
    dispatch(slice.actions.otpSentSuccess({}));
  };
}

// ----------------------------------------------------------------------

export function addCoupon(body) {
  return async () => {
    try {
      const response = await post(`Coupon`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateCoupon(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCoupon(body) {
  return async () => {
    try {
      const response = await put(`Coupon`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateCoupon(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
