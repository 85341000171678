import React, { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import './src/components/aurudu.css';
import Welcome from './src/components/welcome';
import UserInfo from './src/components/userInfo';
import Game from './src/components/game';
import Conditions from './src/components/Conditions';
import Details from './src/components/Details';
import { useParams } from 'react-router-dom';
import WinningPage from './src/components/WinningPage';
import DnBidimu from './src/components/DnBidimu';
import { toast } from 'react-toastify';

export default function Aurudu() {
  const { uuid } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [phone, setPhone] = useState('');

  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <Welcome
            key={1}
            setCurrentPage={() => {
              toast.error(`Let's Try on next year. This year Aurudu game is over.`);
            }}
          />
        );
      case 2:
        return (
          <Details
            key={2}
            setCurrentPage={() => {
              setCurrentPage(3);
            }}
          />
        );
      case 3:
        return (
          <UserInfo
            key={3}
            setCurrentPage={() => {
              setCurrentPage(4);
            }}
            setPhone={setPhone}
            phone={phone}
          />
        );
      case 4:
        return (
          <Conditions
            key={4}
            setCurrentPage={() => {
              setCurrentPage(5);
            }}
          />
        );
      case 5:
        return (
          <DnBidimu
            key={5}
            setCurrentPage={() => {
              setCurrentPage(6);
            }}
          />
        );
      case 6:
        return (
          <Game
            key={6}
            phone={phone}
            setCurrentPage={() => {
              setCurrentPage(6);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='fullPage' style={{ position: 'relative', overflow: 'hidden' }}>
      {uuid ? (
        <WinningPage
          key={2}
          uuid={uuid}
          setCurrentPage={() => {
            setCurrentPage(3);
          }}
        />
      ) : (
        <SwitchTransition mode='out-in'>
          <CSSTransition
            key={currentPage}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames={{
              enter: 'slide-enter',
              enterActive: 'slide-enter-active',
              exit: 'slide-exit',
              exitActive: 'slide-exit-active',
            }}
          >
            {renderPage()}
          </CSSTransition>
        </SwitchTransition>
      )}
    </div>
  );
}
