import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { fCurrency, fCurrencyWithoutSymbol } from '../../../../../../utils/formatNumber';
import { LoadingButton } from '@mui/lab';
import { addPosSale, clearAddData, getPos, hidePOSTab } from '../../../../../../redux/slices/posRedux/posRedux';
import { dispatch, useSelector } from '../../../../../../redux/store';
import { toast } from 'react-toastify';
import { handleNumber } from '../../../../../../utils/common';
import { pdf } from '@react-pdf/renderer';
import PosInvoicePdf from '../posInvoicePdf';
import moment from 'moment';

export default function PaymentSummery({ data, setData, onClickSound, onChangeTab }) {
  const { addData } = useSelector((state) => state.pos);
  const { selectedLocation } = useSelector((state) => state?.location);
  const { loggedInUser } = useSelector((state) => state?.user);
  const [posSent, setPosSent] = React.useState(false);

  const tableHeaderStyle = {
    background: '#f2f2f2',
    padding: '10px',
    borderBottom: '1px solid #ddd',
  };

  const tableFooterStyle = {
    background: '#f3fff3',
    padding: '10px',
    borderTop: '1px solid #ddd',
  };

  const tableRowStyle = {
    borderBottom: '1px solid #ddd',
  };

  const tableCellStyle = {
    padding: '10px',
    textAlign: 'center',
  };

  const totalPayable = data?.orderInfo?.cart?.reduce((sum, cartItem) => {
    const pricingData = cartItem?.pricingData?.find((pricingDataIndex) => pricingDataIndex?.saleTypeId === data?.deliveryInfo?.id);
    return sum + pricingData?.pricing * cartItem?.quantity;
  }, 0);

  const getTotalPaidAmount = (data?.paymentRecords?.payments || []).reduce((sum, value) => sum + Number(value?.amount || 0), 0);

  const balanceAmount = totalPayable - getTotalPaidAmount;

  useEffect(() => {
    if (addData?.data?.insertId) {
      dispatch(getPos(selectedLocation?.id));
      handlePrint(addData?.data?.insertId);
      handlePrint(addData?.data?.insertId, true);
      dispatch(hidePOSTab());
      dispatch(clearAddData());
    } else {
      setPosSent(false);
    }
  }, [addData]);

  const handlePrint = async (orderNumber, isKOT = false) => {
    const paymentTypesUsed = data?.paymentRecords?.payments?.filter((value) => value?.amount > 0)?.map((value) => value?.name);
    const sumOfItemCount = data?.orderInfo?.cart?.reduce((sum, cartItem) => sum + cartItem?.quantity, 0) || 0;
    const pdfBlob = await pdf(
      <PosInvoicePdf
        data={{
          selectedPrinter: localStorage.getItem('selectedPrinter'),
          isReprint: false,
          isKOT: isKOT,
          locationName: selectedLocation?.invoiceName,
          locationAddress: selectedLocation?.address,
          phoneNumber: selectedLocation?.contactNumber,
          orderNumber: orderNumber,
          orderType: data?.deliveryInfo?.name,
          cashierName: loggedInUser?.name,
          cashierDrawerId: data?.posCashDrawersId,
          customerName: data?.customerInfo?.name,
          loyaltyPoint: null,
          tableNumber: data?.orderInfo?.tableNumber,
          specialNote: data?.orderInfo?.specialNote,
          dateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          invoiceItems: data?.orderInfo?.cart.map((item) => {
            const unitPrice = item?.pricingData?.find((pricingDataIndex) => pricingDataIndex?.saleTypeId === data?.deliveryInfo?.id)?.pricing;
            const totalAmount = handleNumber(item.quantity) * handleNumber(unitPrice);
            return {
              title: item.name,
              qty: handleNumber(item.quantity),
              price: handleNumber(unitPrice),
              amount: totalAmount,
            };
          }),
          grandTotal: data?.paymentRecords?.totalPayable || 0,
          givenAmount: data?.paymentRecords?.totalPaidAmount || 0,
          balanceAmount: data?.paymentRecords?.balance || 0,
          paymentMethod: paymentTypesUsed,
          totalItemCount: sumOfItemCount || 0,
        }}
      />
    ).toBlob();

    setPosSent(false);
    sendPDFToBackend(pdfBlob, isKOT ? 'KOT' : 'Invoice');
  };
  const sendPDFToBackend = (blob, name) => {
    const formdata = new FormData();
    formdata.append('pdfFile', blob, `${name}.pdf`);
    formdata.append('pageCount', '1');

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch('http://localhost:3001/print', requestOptions)
      .then((response) => response.text())
      .then((result) => {
        toast.success('Invoice Printed Successfully!');
      })
      .catch((error) => {
        console.error('Error sending PDF:', error);
        toast.error('Error Printing Invoice!');
      });
  };

  const handleRemove = (paymentTypeId) => {
    onClickSound();
    setData((currentDataState) => ({
      ...currentDataState,
      paymentRecords: {
        ...currentDataState?.paymentRecords,
        payments: currentDataState?.paymentRecords?.payments?.map((paymentRecordIndex) => {
          if (paymentRecordIndex?.id === paymentTypeId) {
            return {
              ...paymentRecordIndex,
              amount: 0,
              couponData: {},
            };
          }
          return {
            ...paymentRecordIndex,
            amount: paymentRecordIndex?.amount,
          };
        }),
      },
    }));
  };

  const handlePriceChange = (increment = false, paymentTypeId, amount) => {
    onClickSound();
    setData((currentDataState) => ({
      ...currentDataState,
      paymentRecords: {
        ...currentDataState?.paymentRecords,
        payments: currentDataState?.paymentRecords?.payments?.map((paymentRecordIndex) => {
          if (paymentRecordIndex?.id === paymentTypeId) {
            return {
              ...paymentRecordIndex,
              amount: increment ? Number(paymentRecordIndex?.amount) + Number(amount) : Number(paymentRecordIndex?.amount) - Number(amount),
            };
          }
          return {
            ...paymentRecordIndex,
            amount: paymentRecordIndex?.amount,
          };
        }),
      },
    }));
  };

  return (
    <div>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>#</th>
            <th style={tableHeaderStyle}>Payment Type</th>
            <th style={tableHeaderStyle}>Amount</th>
          </tr>
        </thead>
      </table>
      <div style={{ height: '50vh', overflowY: 'auto', marginTop: '-20px' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
          }}
        >
          <tbody>
            {data?.paymentRecords?.payments?.map((paymentDetails) => {
              if (paymentDetails?.amount !== 0) {
                return (
                  <tr key={paymentDetails?.id} style={tableRowStyle}>
                    <td>
                      <IconButton color='error' onClick={() => handleRemove(paymentDetails?.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </td>
                    <td style={tableCellStyle}>{paymentDetails?.name}</td>
                    <td style={tableCellStyle}>
                      <IconButton color='error' onClick={() => handlePriceChange(false, paymentDetails?.id, 1)}>
                        <RemoveIcon />
                      </IconButton>
                      {fCurrencyWithoutSymbol(paymentDetails?.amount)}
                      <IconButton color='success' onClick={() => handlePriceChange(true, paymentDetails?.id, 1)}>
                        <AddIcon />
                      </IconButton>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <tfoot>
          <tr>
            <th style={tableFooterStyle}>
              <span style={{ fontSize: '12px', color: 'darkgray' }}>Total Payable:</span> <br />
              <span style={{ fontSize: '18px' }}>{fCurrency(totalPayable)}</span>
            </th>
            <th style={tableFooterStyle}>
              <span style={{ fontSize: '12px', color: 'darkgray' }}>Paid Amount:</span>
              <br /> <span style={{ fontSize: '18px' }}>{fCurrency(getTotalPaidAmount)}</span>
            </th>
            <th style={tableFooterStyle}>
              <span style={{ fontSize: '12px', color: 'darkgray' }}>Balance:</span>
              <br /> <span style={{ fontSize: '18px' }}>{fCurrency(balanceAmount)}</span>
            </th>
          </tr>
        </tfoot>
      </table>
      {!posSent && (
        <LoadingButton
          onClick={() => {
            setPosSent(true);
            onClickSound();
            if (data?.paymentRecords?.payments?.filter((value) => value?.amount < 0)?.length > 0) {
              toast.warning("Payment Amount Can't be Negative!");
              setPosSent(false);
            } else {
              const cashAmount = data?.paymentRecords?.payments?.find((value) => value?.id === 1)?.amount || 0;
              if (cashAmount >= data?.paymentRecords?.balance) {
                const verifierRequiredDataNotAdded = data?.paymentRecords?.payments?.filter(
                  (value) => value?.amount > 0 && Boolean(value?.verifierIdRequired) && (!value?.verifierId || value?.verifierId?.length === 0)
                );
                if (verifierRequiredDataNotAdded?.length === 0) {
                  dispatch(addPosSale(data));
                } else {
                  setPosSent(false);
                  toast.warning(`Identifier required for ${verifierRequiredDataNotAdded?.map((value) => value?.name)}`);
                }
              } else {
                setPosSent(false);
                toast.warning('Cash Amount Must be Greater than Balance Amount!');
              }
            }
          }}
          variant='contained'
          size='large'
          style={{ width: '100%', marginTop: '20px' }}
        >
          Complete & Pay Now
        </LoadingButton>
      )}
    </div>
  );
}
