export const GLOBAL_LOCATION_ID = 1;
export const PRINTER_TOOL_URL = 'https://storage.googleapis.com/bubblemania/PROD_ASSETS/PrinterTool.zip';

export const leaveStatus = [
  { id: 1, name: 'Pending', color: '#FFC107' },
  { id: 2, name: 'Approved', color: '#00AB55' },
  { id: 3, name: 'Declined', color: '#FF4842' },
];
export const leaveTypes = [
  { id: 1, name: 'Annual Leave' },
  { id: 2, name: 'Sick Leave' },
  { id: 3, name: 'Maternity Leave' },
  { id: 4, name: 'Paternity Leave' },
  { id: 5, name: 'Unpaid Leave' },
  { id: 6, name: 'Other' },
  { id: 7, name: 'Casual Leave' },
];

export const dayLeaveType = [
  {
    id: 1,
    name: 'Full Day',
    startTime: 'dd/MM/yyyy 00:00:00',
    endTime: 'dd/MM/yyyy 23:59:59',
  },
  // {
  //   id: 2,
  //   name: 'First Half Day',
  //   startTime: 'dd/MM/yyyy 00:00:00',
  //   endTime: 'dd/MM/yyyy 12:00:00',
  // },
  // {
  //   id: 3,
  //   name: 'Second Half Day',
  //   startTime: 'dd/MM/yyyy 12:00:00',
  //   endTime: 'dd/MM/yyyy 23:59:59',
  // },
];
export const CASHFLOW_TYPES = [
  {
    id: 1,
    name: '(Debit) Deposit',
    code: 'DEPOSIT',
    isDebit: true,
    isShow: true,
  },
  {
    id: 2,
    name: '(Debit) POS Sales',
    code: 'DEPOSIT',
    isDebit: true,
    isShow: false,
  },
  {
    id: 3,
    name: '(Credit) Stock Supplier Payments',
    code: 'STOCK_SUPPLIER',
    isDebit: false,
    isShow: true,
  },
  {
    id: 4,
    name: '(Credit) Asset Supplier Payments',
    code: 'ASSET_BASED_SERVICE',
    isDebit: false,
    isShow: true,
  },
  {
    id: 7,
    name: '(Credit) Service Supplier Payments',
    code: 'ASSET_BASED_SERVICE',
    isDebit: false,
    isShow: true,
  },
  {
    id: 5,
    name: '(Credit) Cash Drawer',
    code: 'OTHER_PAYMENTS',
    isDebit: false,
    isShow: false,
  },
  {
    id: 6,
    name: '(Credit) Other Supplier Payments',
    code: 'OTHER_PAYMENTS',
    isDebit: false,
    isShow: true,
  },
];

export const CASHFLOW_PAYMENT_STATUS = [
  {
    id: 1,
    name: 'Pending',
    code: 'PENDING',
    showInitially: false,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: false,
  },
  {
    id: 2,
    name: 'Schedule',
    code: 'SCHEDULED',
    showInitially: true,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: false,
  },
  {
    id: 3,
    name: 'Paid',
    code: 'PAID',
    showInitially: true,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: true,
    visibleOnEdit: true,
  },
  {
    id: 4,
    name: 'Declined',
    code: 'DECLINED',
    showInitially: false,
    color: 'red',
    textColor: 'white',
    visibleOnAdd: false,
    visibleOnEdit: true,
  },
];
