import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
import { fShortenNumber } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3),
}));

// ----------------------------------------------------------------------

DashboardStatBox.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default function DashboardStatBox({ title, value, icon, shortenNumber = false, smallText = 'h3', isIconVisible = true, percentageFromDeposited = false, percentageFromGrossSales = false }) {
  return (
    <RootStyle>
      <div>
        {!shortenNumber && <Typography variant={smallText}>{value}</Typography>}
        {shortenNumber && <Typography variant={smallText}>{fShortenNumber(value)}</Typography>}
        <Typography variant='h6' sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
        {percentageFromDeposited && (
          <Typography variant='p' sx={{ color: 'text.secondary' }}>
            From Deposited : {percentageFromDeposited}%
          </Typography>
        )}
        <br />
        {percentageFromGrossSales && (
          <Typography variant='p' sx={{ color: 'text.secondary' }}>
            From Gross Sale : {percentageFromGrossSales}%
          </Typography>
        )}
      </div>
      {isIconVisible && icon}
    </RootStyle>
  );
}
