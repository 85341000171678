// https://www.veryicon.com/icons/miscellaneous/indata/walking-1.html

import React, { useState } from 'react';
import { Container, Grid } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import DataFilter from './components/filter';
import TotalSalesIcon from './components/icons/totalSalesIcon';
import DashboardStatBox from './components/DashboardStatBox';
import {
  getAverageKotValues,
  getAverageNewCustomerSale,
  getAverageReturningCustomerForLocation,
  getAverageReturningCustomerSale,
  getAverageWalkingCustomerSaleSpentByCustomer,
  getDiningCustomerOrderCount,
  getHourlySalesDateBased,
  getHourlySalesHourBased,
  getItemWiseSales,
  getPaymentTypeForValue,
  getPaymentTypes,
  getPercentage,
  getPickMeOrderCount,
  getReturnWalkingCustomerCount,
  getTakeAwayCustomerOrderCount,
  getTotalNumberOfSales,
  getTotalSaleAmount,
  getTotalSaleOfSaleType,
  getUberOrderCount,
  getWalkingCustomerCount,
} from './components/dashboardCalculations';
import { fCurrency } from '../../../utils/formatNumber';
import SalesCountIcon from './components/icons/salesCountIcon';
import WalkingCustomersIcon from './components/icons/walkingCustomersIcon';
import DeliveryIcon from './components/icons/deliveryIcon';
import LineChartComponent from './components/LineChart';
import PieChartComponent from './components/PieChart';
import SalesTargetViewer from './components/SalesTargetViewer';
import BarChartComponent from './components/BarChart';
import { accessVerify } from '../../../utils/common';
import DinningIcon from './components/icons/dinningIcon';
import { useSelector } from 'react-redux';
import EmployeeMessageDrawer from './components/employeeMessageDrawer';

export default function HomeDashboard() {
  const { themeStretch } = useSettings();
  const [data, setData] = useState([]);
  const [globalData, setGlobalData] = useState([]);

  let otherData;
  ({ otherData: otherData } = useSelector((state) => state.home));

  const totalDepositedAmount =
    getPaymentTypeForValue(data, 'Cash') +
    getPaymentTypeForValue(data, 'Other Cards') * 0.97 +
    getPaymentTypeForValue(data, 'Amex Cards') * 0.97 +
    getPaymentTypeForValue(data, 'Pickme Food') * 0.66 +
    getPaymentTypeForValue(data, 'Uber Eats') * 0.66;

  return (
    <Page title='General: Banking'>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <DataFilter data={data} setData={setData} setGlobalData={setGlobalData} />

          {/*----------------------------------------------*/}

          <Grid item xs={12}>
            {accessVerify('DASHBOARD_ANALYTICS_SALES_TARGET') && (
              <SalesTargetViewer title='Monthly Sales Target' value={fCurrency(getTotalSaleAmount(data))} icon={<TotalSalesIcon />} shortenNumber={false} />
            )}
          </Grid>

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_TOTAL_SALE_AMOUNT') && (
              <DashboardStatBox title='Total Gross Sale Amount' value={fCurrency(getTotalSaleAmount(data))} icon={<TotalSalesIcon />} shortenNumber={false} />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_COST_OF_GOOD_SOLD') && (
              <DashboardStatBox
                title='Cost of Good Sold'
                value={fCurrency(otherData?.costOfGoodSold)}
                icon={<SalesCountIcon />}
                percentageFromDeposited={getPercentage(totalDepositedAmount, otherData?.costOfGoodSold)}
                percentageFromGrossSales={getPercentage(getTotalSaleAmount(data), otherData?.costOfGoodSold)}
              />
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_TOTAL_INGREDIENT_COST') && (
              <DashboardStatBox
                title={'Total Ingredient Cost'}
                value={fCurrency(otherData?.ingredientCost)}
                icon={<SalesCountIcon />}
                percentageFromDeposited={getPercentage(totalDepositedAmount, otherData?.ingredientCost)}
                percentageFromGrossSales={getPercentage(getTotalSaleAmount(data), otherData?.ingredientCost)}
              />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_TOTAL_WASTE_COST') && (
              <DashboardStatBox
                title='Total Waste Cost'
                value={fCurrency(otherData?.wasteCost)}
                icon={<SalesCountIcon />}
                percentageFromDeposited={getPercentage(totalDepositedAmount, otherData?.wasteCost)}
                percentageFromGrossSales={getPercentage(getTotalSaleAmount(data), otherData?.wasteCost)}
              />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_TOTAL_USED_COST') && (
              <DashboardStatBox
                title='Total Used Cost'
                value={fCurrency(otherData?.usedCost)}
                icon={<SalesCountIcon />}
                percentageFromDeposited={getPercentage(totalDepositedAmount, otherData?.usedCost)}
                percentageFromGrossSales={getPercentage(getTotalSaleAmount(data), otherData?.usedCost)}
              />
            )}
          </Grid>

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={1.5}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Gross Voucher Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Voucher'))} icon={<SalesCountIcon />} />
            )}
          </Grid>
          <Grid item xs={12} md={1.5}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Gross Discount Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Discount'))} icon={<SalesCountIcon />} />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='Walking Customer Count' value={getWalkingCustomerCount(data)} icon={<SalesCountIcon />} />}
          </Grid>
          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='Returning Customer Count' value={getReturnWalkingCustomerCount(data)} icon={<SalesCountIcon />} />}
          </Grid>
          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='New Customer Count' value={getWalkingCustomerCount(data) - getReturnWalkingCustomerCount(data)} icon={<SalesCountIcon />} />
            )}
          </Grid>

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox
                title='Returning Customer Percentage'
                value={getAverageReturningCustomerForLocation(data, ((getReturnWalkingCustomerCount(globalData) / getWalkingCustomerCount(globalData)) * 100).toFixed(2))}
                icon={<SalesCountIcon />}
                smallText={'h6'}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox
                title='Walking Customer Average Spent'
                value={getAverageKotValues(data, getAverageWalkingCustomerSaleSpentByCustomer(globalData))}
                icon={<SalesCountIcon />}
                smallText={'h6'}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Walking Returning Customer Average Spent' value={getAverageReturningCustomerSale(data, globalData)} icon={<SalesCountIcon />} smallText={'h6'} />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Walking New Customer Average Spent' value={getAverageNewCustomerSale(data, globalData)} icon={<SalesCountIcon />} smallText={'h6'} />
            )}
          </Grid>

          {/*----------------------------------------------*/}

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Avearage Walking KOT Value' value={fCurrency(getTotalSaleAmount(data) / getTotalNumberOfSales(data))} icon={<SalesCountIcon />} />
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_WALK_IN_COUNT') && <DashboardStatBox title='Dining Sale' value={fCurrency(getTotalSaleOfSaleType(data, [1]))} icon={<DinningIcon />} />}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_WALK_IN_COUNT') && <DashboardStatBox title='Take Away Sale' value={fCurrency(getTotalSaleOfSaleType(data, [2]))} icon={<WalkingCustomersIcon />} />}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_PICKME_COUNT') && <DashboardStatBox title='Pickme Sale' value={fCurrency(getTotalSaleOfSaleType(data, [3]))} icon={<DeliveryIcon />} />}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_UBER_COUNT') && <DashboardStatBox title='Uber Sale' value={fCurrency(getTotalSaleOfSaleType(data, [4]))} icon={<DeliveryIcon />} />}
          </Grid>

          {/*----------------------------------------------*/}

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='Total KOT Count' value={getTotalNumberOfSales(data)} icon={<SalesCountIcon />} />}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_WALK_IN_COUNT') && <DashboardStatBox title='Dining KOT Count' value={getDiningCustomerOrderCount(data)} icon={<DinningIcon />} />}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_WALK_IN_COUNT') && <DashboardStatBox title='Take Away KOT Count' value={getTakeAwayCustomerOrderCount(data)} icon={<WalkingCustomersIcon />} />}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_PICKME_COUNT') && <DashboardStatBox title='Pickme KOT Count' value={getPickMeOrderCount(data)} icon={<DeliveryIcon />} />}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_UBER_COUNT') && <DashboardStatBox title='Uber KOT Count' value={getUberOrderCount(data)} icon={<DeliveryIcon />} />}
          </Grid>

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_PAYMENT_TYPES') && <PieChartComponent title='Payment Types' value={getPaymentTypes(data)} />}
          </Grid>
          <Grid item xs={12} md={8}>
            {accessVerify('DASHBOARD_ANALYTICS_HOURLY_SALES_AMOUNT_GRAPH') && <LineChartComponent title='Hourly Sales Amounts (Date Based)' value={getHourlySalesDateBased(data)} />}
          </Grid>

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Total Gross Sales' isIconVisible={false} value={fCurrency(getTotalSaleAmount(data))} icon={<SalesCountIcon />} />
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Gross Cash Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Cash'))} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Other Card Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Other Cards'))} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Amex Cards Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Amex Cards'))} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Gross Pickme Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Pickme Food'))} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Gross Uber Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Uber Eats'))} icon={<SalesCountIcon />} />
            )}
          </Grid>

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Total Deposited Sales' isIconVisible={false} value={fCurrency(totalDepositedAmount)} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Deposited Cash Sales' isIconVisible={false} value={fCurrency(Math.floor(getPaymentTypeForValue(data, 'Cash') / 100) * 100)} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Deposited Other Card Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Other Cards') * 0.97)} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Deposited Amex Card Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Amex Cards') * 0.97)} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Deposited Pickme Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Pickme Food') * 0.66)} icon={<SalesCountIcon />} />
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && (
              <DashboardStatBox title='Deposited Uber Sales' isIconVisible={false} value={fCurrency(getPaymentTypeForValue(data, 'Uber Eats') * 0.66)} icon={<SalesCountIcon />} />
            )}
          </Grid>

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='Verified Total Deposited Sales' isIconVisible={false} value={'-'} icon={<SalesCountIcon />} />}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='Verified Deposited Cash Sales' isIconVisible={false} value={'-'} icon={<SalesCountIcon />} />}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='Verified Deposited Card Sales' isIconVisible={false} value={'-'} icon={<SalesCountIcon />} />}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='Verified Deposited Pickme Sales' isIconVisible={false} value={'-'} icon={<SalesCountIcon />} />}
          </Grid>

          <Grid item xs={12} md={2}>
            {accessVerify('DASHBOARD_ANALYTICS_KOT_COUNT') && <DashboardStatBox title='Verified Deposited Uber Sales' isIconVisible={false} value={'-'} icon={<SalesCountIcon />} />}
          </Grid>

          {/*----------------------------------------------*/}

          <Grid item xs={12} md={8}>
            {accessVerify('DASHBOARD_ANALYTICS_HOURLY_SALES_AMOUNT_GRAPH') && <LineChartComponent title='Hourly Sales Amounts (Hour Based)' value={getHourlySalesHourBased(data)} />}
          </Grid>
          <Grid item xs={12} md={4}>
            {accessVerify('DASHBOARD_ANALYTICS_ITEM_WISE_SALE_COUNT_CHARTS') && <PieChartComponent title='Item Count Wise Sales' value={getItemWiseSales(data, 'pie')} />}
          </Grid>
          <Grid item xs={12} md={8}>
            {accessVerify('DASHBOARD_ANALYTICS_ITEM_WISE_SALE_COUNT_CHARTS') && <BarChartComponent title='Item Count Wise Sales' value={getItemWiseSales(data, 'bar')} />}
          </Grid>
        </Grid>
        <EmployeeMessageDrawer globalData={globalData} data={data} />
      </Container>
    </Page>
  );
}
