import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import DataGridTable from '../../../components/table/DataGridTable';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import { addSupplier, getSuppliers, updateSupplier } from '../../../redux/slices/stockManagementRedux/supplierRedux';
import { getAllFoods } from '../../../redux/slices/stockManagementRedux/foodRedux';
import { accessVerify, capitalize, safeJSONParse } from '../../../utils/common';
import { useLocation, useParams } from 'react-router-dom';
import PermissionRequired from '../../errorPages/permissionRequired';

export default function SupplierManagement() {
  const [isStockSupplier, setIsStockSupplier] = useState(true);
  const { supplierType } = useParams();
  const location = useLocation();
  const dataModel = {
    id: null,
    name: null,
    code: null,
    address: null,
    companyPhone: null,
    contactPersonName: null,
    contactPersonPhone: null,
    accountName: null,
    accountNumber: null,
    bankName: null,
    bankBranch: null,
    type: supplierType,
  };

  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [selectedFoodList, setSelectedFoodList] = useState([]);

  const { data, addData } = useSelector((state) => state.supplier);

  let allData;
  ({ allData } = useSelector((state) => state?.food));

  useEffect(() => {
    dispatch(getSuppliers(supplierType));
    dispatch(getAllFoods());
  }, [location, supplierType]);

  useEffect(() => {
    setSupplierList(data?.filter((value) => value?.type === supplierType));
    setIsModelOpen(false);
  }, [data, isStockSupplier]);

  useEffect(() => {
    setIsStockSupplier(supplierType === 'stockSupplier');
  }, [supplierType]);

  const manageModel = (modelData, type) => {
    setSelectedFoodList([]);
    if (modelData?.foodList?.length > 0) {
      setSelectedFoodList(
        safeJSONParse(modelData?.foodList)?.map((value) => {
          return allData?.find((food) => food?.id === value?.foodId);
        })
      );
    }

    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedObject(dataModel);
      } else {
        setSelectedObject(modelData);
      }
    }
  };

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Code is required'),
    contactPersonName: Yup.string().required('Contact Person Name is required'),
    contactPersonPhone: Yup.string().required('Contact Person Phone Time is required'),
    accountName: Yup.string().required('Account Name is required'),
    accountNumber: Yup.string().required('Account Number is required'),
    bankName: Yup.string().required('Bank Name is required'),
    bankBranch: Yup.string().required('Bank Branch is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: selectedObject?.name || '',
      code: selectedObject?.code || '',
      address: selectedObject?.address || '',
      companyPhone: selectedObject?.companyPhone || '',
      contactPersonName: selectedObject?.contactPersonName || '',
      contactPersonPhone: selectedObject?.contactPersonPhone || '',
      accountName: selectedObject?.accountName || '',
      accountNumber: selectedObject?.accountNumber || '',
      bankName: selectedObject?.bankName || '',
      bankBranch: selectedObject?.bankBranch || '',
      type: supplierType,
      isStockSupplier: isStockSupplier || '',
    }),
    [selectedObject]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isAdd && selectedObject) {
      reset(defaultValues);
    }
    if (!isAdd) {
      reset(defaultValues);
    }
  }, [isAdd, selectedObject]);

  const onSubmit = async (value) => {
    try {
      const tempDataObj = {
        name: value?.name || '',
        code: value?.code || '',
        address: value?.address || '',
        companyPhone: value?.companyPhone || '',
        contactPersonName: value?.contactPersonName || '',
        contactPersonPhone: value?.contactPersonPhone || '',
        accountName: value?.accountName || '',
        accountNumber: value?.accountNumber || '',
        bankName: value?.bankName || '',
        bankBranch: value?.bankBranch || '',
        isStockSupplier: isStockSupplier || '',
        foodList: selectedFoodList?.map((value) => value?.id) || '',
        type: supplierType,
      };
      if (isAdd) {
        dispatch(addSupplier(tempDataObj));
      } else {
        dispatch(updateSupplier({ ...tempDataObj, id: selectedObject?.id }));
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      // reset();
      setValue('startTime', null);
      setValue('endTime', null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getSuppliers(supplierType));
    }
  }, [addData]);

  const columns = !supplierList?.[0]
    ? []
    : Object.keys(supplierList?.[0]).map((value) => {
        return {
          accessorKey: value,
          header: capitalize(value),
        };
      });

  const [rowSelection, setRowSelection] = useState(false);
  return (
    <Page title={`${capitalize(supplierType)} List`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${capitalize(supplierType)} List`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Stock',
              href: PATH_DASHBOARD.stocks.root,
            },
            {
              name: `${capitalize(supplierType)} List`,
            },
          ]}
          action={
            <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => manageModel(dataModel, 'add')}>
              New {`${capitalize(supplierType)}`}
            </Button>
          }
        />

        {accessVerify('SUPPLIER_VIEW') ? (
          <DataGridTable
            name={'Suppliers'}
            data={supplierList}
            column={columns}
            isRowClickable={true}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            renderRowActionItems={() => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Supplier</h1>
          </Box>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Supplier Company Information
                    </Typography>
                    <Stack spacing={3}>
                      <RHFTextField name='name' label='Supplier Name' />
                      <RHFTextField name='code' label='Supplier Code' />
                      <RHFTextField name='address' label='Supplier Address' />
                      <RHFTextField name='companyPhone' label='Supplier Company Phone' />
                    </Stack>
                  </Card>
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Company Contact Person
                    </Typography>
                    <Stack spacing={3}>
                      <RHFTextField name='contactPersonName' label='Contact Person Name' />
                      <RHFTextField name='contactPersonPhone' label='Contact Person PhoneNumber' />
                    </Stack>
                  </Card>
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Banking Details
                    </Typography>
                    <Stack spacing={3}>
                      <RHFTextField name='accountName' label='Bank Account Name' />
                      <RHFTextField name='accountNumber' label='Bank Account Number' />
                      <RHFTextField name='bankName' label='Bank Name' />
                      <RHFTextField name='bankBranch' label='Branch Name' />
                    </Stack>
                  </Card>
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    {isStockSupplier && (
                      <>
                        <Controller
                          name='selectedFoodPrIngredient'
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              onChange={(event, newValue) => {
                                setSelectedFoodList([...selectedFoodList, newValue]);
                              }}
                              onClick={(event, newValue) => {
                                setSelectedFoodList([...selectedFoodList, newValue]);
                              }}
                              options={allData
                                ?.filter((value) => selectedFoodList?.filter((selectedFood) => selectedFood?.id === value?.id)?.length === 0 && value?.type === 'ingredient')
                                ?.map((value) => {
                                  return {
                                    ...value,
                                    label: `${value?.name} - ${value?.description}`,
                                    value: value?.id,
                                  };
                                })}
                              value={
                                allData?.find((value) => value.id === field.value)?.name
                                  ? `${allData?.find((value) => value.id === field.value)?.name} - ${allData?.find((value) => value.id === field.value)?.description}`
                                  : null
                              }
                              renderInput={(params) => <TextField label='Select Selling Items' {...params} />}
                            />
                          )}
                        />
                        <Box
                          style={{ marginBottom: '20px' }}
                          sx={{
                            display: 'grid',
                            rowGap: 2,
                            columnGap: 2,
                            gridTemplateColumns: {
                              xs: 'repeat(1, 1fr)',
                              sm: 'repeat(2, 1fr)',
                            },
                          }}
                        >
                          {selectedFoodList?.map((value) => (
                            <Button size='small' color='warning' variant='contained' sx={{ whiteSpace: 'nowrap' }}>
                              {value?.name}
                            </Button>
                          ))}
                        </Box>
                      </>
                    )}
                    <LoadingButton type='submit' variant='contained' size='large' loading={isSubmitting} style={{ width: '100%' }}>
                      {isAdd ? 'Add Supplier' : 'Save Changes'}
                    </LoadingButton>
                    <Button color='info' variant='outlined' size='large' loading={isSubmitting} style={{ width: '100%' }} onClick={() => manageModel(dataModel, 'add')}>
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
