import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { getAllFoods, getFoods } from '../../../redux/slices/stockManagementRedux/foodRedux';
import { getUnits } from '../../../redux/slices/stockManagementRedux/unitRedux';
import { getFoodCategorys } from '../../../redux/slices/stockManagementRedux/foodCategoryRedux';
import { getAllSaleTypes } from '../../../redux/slices/saleRedux/salesRedux';
import { fCurrency } from '../../../utils/formatNumber';
import { accessVerify, capitalize, safeJSONParse } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';

export default function FoodList() {
  const navigate = useNavigate();
  const { type } = useParams();
  const { themeStretch } = useSettings();
  const [foodList, setFoodList] = useState([]);
  const { saleTypes } = useSelector((state) => state.sales);

  let foodData;
  let allData;
  ({ data: foodData, allData } = useSelector((state) => state?.food));
  let foodCategoryData;
  ({ data: foodCategoryData } = useSelector((state) => state?.foodCategory));
  let units;
  ({ data: units } = useSelector((state) => state?.unit));

  useEffect(() => {
    dispatch(getAllFoods());
    dispatch(getUnits());
    dispatch(getAllSaleTypes());
  }, []);

  useEffect(() => {
    dispatch(getAllFoods());
    dispatch(getFoods(type));
    dispatch(getFoodCategorys(type));
  }, [type]);

  useEffect(() => {
    if (units) {
      setFoodList(
        foodData?.map((foodItem) => {
          try {
            const pricingDataArray = safeJSONParse(foodItem?.pricingData);
            const recipeWeightDataArray = safeJSONParse(foodItem?.recipeData);
            const locationDataArray = safeJSONParse(foodItem?.locationData);

            const updatedPricingData = {};
            units.forEach((unit) => {
              updatedPricingData[`sellingTypeId_${unit.id}`] = null;
            });
            pricingDataArray?.forEach((pricingItem) => {
              const key = `sellingTypeId_${pricingItem?.saleTypeId}`;
              const value = pricingItem?.pricing;
              updatedPricingData[key] = value;

              if (!isNaN(value) && value !== null && value !== undefined) {
                updatedPricingData[key] = value;
              } else {
                updatedPricingData[key] = 0;
              }
            });

            const updatedRecipeData = {};
            allData.forEach((unit) => {
              updatedRecipeData[`recipe_weight_${unit.id}`] = null;
            });

            recipeWeightDataArray?.forEach((weightItem) => {
              const key = `recipe_weight_${weightItem?.ingredientId}`;
              const value = weightItem?.qty;
              if (!isNaN(value) && value !== null && value !== undefined) {
                updatedRecipeData[key] = value;
              } else {
                updatedRecipeData[key] = 0;
              }
            });
            const foodLocationData = {};
            allData.forEach((unit) => {
              updatedRecipeData[`location_availability_${unit.id}`] = null;
            });

            locationDataArray?.forEach((locationIndex) => {
              if (locationIndex?.locationID) {
                foodLocationData[`location_availability_${locationIndex?.locationID}`] = true;
              }
            });
            return {
              ...foodItem,
              ...updatedPricingData,
              ...updatedRecipeData,
              ...foodLocationData,
            };
          } catch (e) {
            console.log(e);
            return {};
          }
        })
      );
    }
  }, [foodData, foodCategoryData, units]);

  const columns = !foodList?.[0]
    ? []
    : Object.keys(foodList?.[0])
        .map((value) => {
          if (value === 'foodCategoryId') {
            return {
              accessorKey: 'foodCategoryId',
              header: 'Food Category',
              Cell: ({ cell, row }) => foodCategoryData?.filter((item) => cell.getValue() === item.id)[0]?.name,
            };
          }

          if (value === 'unitId') {
            return {
              accessorKey: 'unitId',
              header: 'Unit',
              Cell: ({ cell, row }) => units?.filter((item) => cell.getValue() === item.id)[0]?.name,
            };
          }
          if (['pricingData', 'isBeverage', 'isVegetarian', 'isVariation', 'recipeData', 'variation', 'locationData'].includes(value)) return null;
          if (value.includes('recipe_weight') || value.includes('location_availability')) return null;

          if (value.includes('sellingTypeId_')) {
            if (type === 'menu') {
              return {
                accessorKey: value,
                header: capitalize(saleTypes?.filter((unitData) => unitData?.id === Number(value.replace('sellingTypeId_', '')))?.[0]?.name) + ' Price',
                Cell: ({ cell, row }) => fCurrency(Number(cell.getValue())),
              };
            } else {
              return null;
            }
          }

          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        .filter((column) => column !== null);

  const [rowSelection, setRowSelection] = useState(false);

  return (
    <Page title={`Food List - (${capitalize(type)})`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Food List - (${capitalize(type)})`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Stock',
              href: '',
            },
            { name: `Food List - (${capitalize(type)})` },
          ]}
          action={
            <Link key={`add-new-link`} to={`/dashboard/foods/food/${type}/manage/new`}>
              <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />}>
                New Food
              </Button>
            </Link>
          }
        />

        {accessVerify('FOOD_VIEW') ? (
          <DataGridTable
            name={'Food List'}
            data={foodList}
            column={columns}
            isLoading={false}
            isRowClickable={true}
            onRowClick={(row) => {
              navigate(`/dashboard/foods/food/${type}/manage/${row?.original?.id}`, '_blank');
            }}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}
