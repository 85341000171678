import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Autocomplete, Box, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
// utils
import { fData } from '../../../../../utils/formatNumber';
// _mock
// components
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../../../components/hook-form';
import { dispatch, useSelector } from '../../../../../redux/store';
import { getSpecificUserData, updateUser } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { imageUpload } from '../../../../../inteceptor';
import { toast } from 'react-toastify';
import { getLocations } from '../../../../../redux/slices/settingsManagementRedux/locationsRedux';
import useAuth from '../../../../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function UserAccountGeneral({ userId }) {
  const { sendResetLink } = useAuth();
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
  });

  const { selectedUser, addData } = useSelector((state) => state.user);
  let locationData;
  ({ data: locationData } = useSelector((state) => state.location));

  const [currentUserData, setCurrentUserData] = useState({});

  useEffect(() => {
    if (userId) dispatch(getSpecificUserData(userId));
    dispatch(getLocations());
  }, [userId]);

  useEffect(() => {
    if (addData?.data?.affectedRows > 0) {
      dispatch(getSpecificUserData(userId));
    }
  }, [addData]);

  useEffect(() => {
    setCurrentUserData(selectedUser?.[0]);
    reset(selectedUser?.[0]);
  }, [selectedUser]);

  const defaultValues = useMemo(
    () => ({
      ...currentUserData,
      id: currentUserData?.id || null,
      uId: currentUserData?.uId || null,
      userImage: currentUserData?.userImage || '',
      fullName: currentUserData?.fullName || '',
      name: currentUserData?.name || '',
      email: currentUserData?.email || '',
      nic: currentUserData?.nic || '',
      designation: currentUserData?.designation || '',
      birthday: currentUserData?.birthday || '',
      joiningDate: currentUserData?.joiningDate || '',
      gender: currentUserData?.gender || '',
      epfNumber: currentUserData?.epfNumber || '',
      etfNumber: currentUserData?.etfNumber || '',
      NIC_Front: currentUserData?.NIC_Front || '',
      NIC_Back: currentUserData?.NIC_Back || '',
      phoneNumber: currentUserData?.phoneNumber || '',
      telephoneNumber: currentUserData?.telephoneNumber || '',
      emergencyContactName: currentUserData?.emergencyContactName || '',
      emergencyContact: currentUserData?.emergencyContact || '',
      emergencyContact2Name: currentUserData?.emergencyContact2Name || '',
      emergencyContact2: currentUserData?.emergencyContact2 || '',
      userProfileID: currentUserData?.userProfileID || '',
      locationId: currentUserData?.locationId || null,
      status: currentUserData?.status || '',
      address: currentUserData?.address || '',
      city: currentUserData?.city || '',
      zipCode: currentUserData?.zipCode || '',
      about: currentUserData?.about || '',
      accountName: currentUserData?.accountName || '',
      bank: currentUserData?.bank || '',
      branch: currentUserData?.branch || '',
      accountNumber: currentUserData?.accountNumber || '',
      bloodType: currentUserData?.bloodType || '',
      terminationOrReginDate: currentUserData?.terminationOrReginDate || '',
    }),
    [currentUserData]
  );

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitting },
  } = methods;

  function validateUserData(userData) {
    const errors = {};

    const phoneValidationRegex = /^94\d{9}$/;
    if (userData.phoneNumber && !phoneValidationRegex.test(userData.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }
    if (userData.telephoneNumber && !phoneValidationRegex.test(userData.telephoneNumber)) {
      errors.telephoneNumber = 'Invalid telephone number format';
    }
    if (userData.emergencyContact && !phoneValidationRegex.test(userData.emergencyContact)) {
      errors.emergencyContact = 'Invalid emergency contact number format';
    }
    if (userData.emergencyContact2 && !phoneValidationRegex.test(userData.emergencyContact2)) {
      errors.emergencyContact2 = 'Invalid emergency contact number 2 format';
    }
    return errors;
  }

  const onSubmit = async (value) => {
    const errors = validateUserData(value);
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).map((value) => {
        toast.error(errors[value]);
        toast.error("Make sure contact numbers start with '94' and have 9 digits.");
      });
      console.log('Validation errors:', errors);
    } else {
      try {
        const tempDataObj = {
          ...currentUserData,
          id: currentUserData?.id || null,
          uId: value?.uId || null,
          userImage: value?.userImage || '',
          name: value?.name || '',
          fullName: value?.fullName || '',
          email: value?.email || '',
          nic: value?.nic || '',
          designation: value?.designation || '',
          birthday: value?.birthday || '',
          joiningDate: value?.joiningDate || '',
          gender: value?.gender || '',
          epfNumber: value?.epfNumber || '',
          etfNumber: value?.etfNumber || '',
          NIC_Front: value?.NIC_Front || '',
          NIC_Back: value?.NIC_Back || '',
          phoneNumber: value?.phoneNumber || '',
          telephoneNumber: value?.telephoneNumber || '',
          emergencyContactName: value?.emergencyContactName || '',
          emergencyContact: value?.emergencyContact || '',
          emergencyContact2Name: value?.emergencyContact2Name || '',
          emergencyContact2: value?.emergencyContact2 || '',
          userProfileID: value?.userProfileID || '',
          locationId: value?.locationId || '',
          status: value?.status || '',
          address: value?.address || '',
          city: value?.city || '',
          zipCode: value?.zipCode || '',
          about: value?.about || '',
          accountName: value?.accountName || '',
          bank: value?.bank || '',
          branch: value?.branch || '',
          accountNumber: value?.accountNumber || '',
          bloodType: value?.bloodType || '',
          terminationOrReginDate: value?.terminationOrReginDate || '',
        };

        dispatch(updateUser(tempDataObj));

        await new Promise((resolve) => setTimeout(resolve, 500));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      imageUpload(
        file,
        'UserProfileImages',
        (data) => {
          if (data?.status) {
            setValue('userImage', data?.url);
          } else {
            console.log(data);
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
        }
      );
      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );
  const handleSendResetLink = async () => {
    const { email } = getValues();
    await sendResetLink(email).then((response) => {
      if (response?.status) {
        toast.success('Password reset link sent successfully');
      } else {
        toast.error('Failed to send password reset link');
      }
    });
  };
  const bloodTypeOptions = [
    { label: 'A+', value: 'A+' },
    { label: 'A-', value: 'A-' },
    { label: 'B+', value: 'B+' },
    { label: 'B-', value: 'B-' },
    { label: 'AB+', value: 'AB+' },
    { label: 'AB-', value: 'AB-' },
    { label: 'O+', value: 'O+' },
    { label: 'O-', value: 'O-' },
  ];

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ py: 2, px: 2, textAlign: 'center' }}>
              <RHFUploadAvatar
                name='userImage'
                accept='image/*'
                maxSize={5045728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant='caption'
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(1048576)}
                  </Typography>
                }
              />
            </Card>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Typography variant='subtitle1'>Bank Details</Typography>
                <RHFTextField name='accountName' label="Account Holder's Name" />
                <RHFTextField name='bank' label='Bank' />
                <RHFTextField name='branch' label='Branch' />
                <RHFTextField name='accountNumber' label='Account Number' />
              </Stack>
            </Card>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ py: 4, px: 3 }}>
            <Typography variant='subtitle1'>Personal Information</Typography>
            <Box
              sx={{
                display: 'grid',
                py: 3,
                rowGap: 2,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                },
              }}
            >
              <RHFTextField name='fullName' label='Full Name' />
              <RHFTextField name='name' label='Calling Name' />
              <RHFTextField name='email' label='Email Address' disabled />
              <RHFTextField name='nic' label='NIC Number' />
              <RHFTextField name='designation' label='Designation' />
              <Controller
                name='birthday'
                control={control}
                render={({ field }) => <MobileDatePicker {...field} label='Birthday' inputFormat='dd/MM/yyyy' renderInput={(params) => <TextField {...params} fullWidth />} />}
              />
              <Controller
                name='joiningDate'
                control={control}
                render={({ field }) => <MobileDatePicker {...field} label='Joining Date' inputFormat='dd/MM/yyyy' renderInput={(params) => <TextField {...params} fullWidth />} />}
              />
              <Controller
                name='terminationOrReginDate'
                control={control}
                render={({ field }) => <MobileDatePicker {...field} label='Termination & Resignation Date' inputFormat='dd/MM/yyyy' renderInput={(params) => <TextField {...params} fullWidth />} />}
              />
              <Controller
                name='gender'
                control={control}
                render={({ field }) => (
                  <Autocomplete {...field} onChange={(event, newValue) => field.onChange(newValue)} options={['Male', 'Female']} renderInput={(params) => <TextField label='Gender' {...params} />} />
                )}
              />
              <RHFTextField name='epfNumber' label='EPF Number' />
              <RHFTextField name='etfNumber' label='ETF Number' />
              <RHFTextField name='phoneNumber' label='Phone Number' />
              <RHFTextField name='telephoneNumber' label='Telephone Number' />
              <RHFTextField name='emergencyContactName' label='Emergency Contact Name' />
              <RHFTextField name='emergencyContact' label='Emergency Contact' />
              <RHFTextField name='emergencyContact2Name' label='Emergency Contact 2 Name' />
              <RHFTextField name='emergencyContact2' label='Emergency Contact 2' />
              <RHFTextField name='address' label='Address' />
              <RHFTextField name='city' label='City' />
              <RHFTextField name='zipCode' label='Zip/Code' />
              <Controller
                name='bloodType'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={bloodTypeOptions.find((option) => option.value === field.value) || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.value || '');
                    }}
                    options={bloodTypeOptions}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label='Blood Type' />}
                  />
                )}
              />

              <Controller
                name='locationId'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={locationData?.find((value) => value.id === field.value)?.name || null}
                    onChange={(event, newValue) => {
                      field.onChange(newValue?.id || null);
                    }}
                    options={locationData
                      ?.filter((value) => ![1, 5]?.includes(value?.id))
                      ?.map((value) => ({
                        ...value,
                        label: value?.name,
                        value: value?.id,
                      }))}
                    renderInput={(params) => <TextField label='Working Location' {...params} />}
                  />
                )}
              />
            </Box>

            <Stack spacing={3} alignItems='flex-end' sx={{ mt: 3 }}>
              <RHFTextField name='about' multiline rows={4} label='About' />
            </Stack>

            <Stack direction='row' spacing={3} alignItems='flex-end' sx={{ mt: 3 }}>
              <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
                Save Changes
              </LoadingButton>
              <LoadingButton variant='outlined' onClick={() => handleSendResetLink()}>
                Send Password Reset Link
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
