import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Chip, Container, Grid, Stack, SwipeableDrawer, Switch, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import { useParams } from 'react-router-dom';
import { addCoupon, getCoupon, updateCoupon } from '../../../redux/slices/marketingRedux/couponRedux';
import { fCurrency } from '../../../utils/formatNumber';
import PermissionRequired from '../../errorPages/permissionRequired';

export default function Coupons() {
  const { type = '' } = useParams();
  const isDiscount = type === 'discount';

  let allLocations;
  let selectedLocation;
  ({ data: allLocations, selectedLocation } = useSelector((state) => state.location));

  const dataModel = {
    id: '',
    promotionType: type,
    code: '',
    name: '',
    type: 'Fixed',
    minSaleRequired: '',
    expiryDate: '',
    discountAmountOrPercentage: '',
    maxDiscountPerSale: '',
    currentCouponCount: '',
    status: true,
    canSameUserRedeemMulti: true,
    linkedCustomers: false,
    locationId: selectedLocation?.id,
    logData: [],
  };

  const typeOptions = [
    { label: 'Fixed Amount', value: 'Fixed' },
    { label: 'Percentage', value: 'Percentage' },
  ];

  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [assetsList, setCouponList] = useState([]);

  const { data, addData } = useSelector((state) => state.coupon);

  useEffect(() => {
    dispatch(getCoupon(selectedLocation?.id, type));
  }, [selectedLocation, type]);

  useEffect(() => {
    setCouponList(
      data?.map((value) => {
        return {
          ...value,
          expiryDate: value?.expiryDate ? utcMoment(value?.expiryDate).format('YYYY-MM-DD') : '',
          couponLog: safeJSONParse(value?.couponLog),
          couponCustomers: safeJSONParse(value?.couponCustomers),
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getCoupon(selectedLocation?.id, type));
    }
  }, [addData]);

  useEffect(() => {
    console.log(selectedDataObj);
  }, [selectedDataObj]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !assetsList?.[0]
    ? []
    : Object.keys(assetsList?.[0])
        .map((value) => {
          if (['canSameUserRedeemMulti'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                const rowValue = row?.original?.[value];
                return rowValue === 1 ? 'Yes' : 'No';
              },
            };
          } else if (['status'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                const rowValue = row?.original?.[value];
                return rowValue === 1 ? 'Active' : 'In-Active';
              },
            };
          } else if (['expiryDate'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          } else if (['userId', 'locationId', 'couponLog', 'couponCustomers'].includes(value)) {
            return {};
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    const convertEmptyStringsToNull = (obj) => {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'string' && obj[key].length === 0) {
          newObj[key] = null;
        } else {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    };

    const updatedDataObj = convertEmptyStringsToNull(selectedDataObj);

    if (updatedDataObj.name.length === 0) {
      toast.error('Asset name is required!');
    } else if (updatedDataObj.code.length === 0) {
      toast.error('Code is required!');
    } else {
      dispatch(isAdd ? addCoupon(updatedDataObj) : updateCoupon(updatedDataObj));
    }
  };

  return (
    <Page title={isDiscount ? 'Discounts List' : `Vouchers List`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={isDiscount ? 'Discounts List' : `Vouchers List`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Marketing',
              href: '',
            },
            { name: `${isDiscount ? 'Discounts List' : 'Vouchers List'}` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New {isDiscount ? 'Discount Coupon' : 'Voucher Coupon'}
            </Button>
          }
        />

        {accessVerify('COUPONS_VIEW') ? (
          <DataGridTable
            name={isDiscount ? 'Discounts List' : 'Vouchers List'}
            data={assetsList}
            column={columns}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isRowClickable={true}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>
              {isAdd ? 'Add' : 'Edit'} {isDiscount ? 'Discount Coupon' : 'Voucher Coupon'}
            </h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    {isDiscount ? 'Discount Coupon' : 'Voucher Coupon'} Information
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Name' value={selectedDataObj?.name} onChange={(e) => updateEditingData('name', e.target.value)} />
                    <TextField fullWidth label='Code' value={selectedDataObj?.code} onChange={(e) => updateEditingData('code', e.target.value)} />
                    <Autocomplete
                      onChange={(event, newValue) => {
                        updateEditingData('type', newValue?.value);
                      }}
                      options={typeOptions}
                      value={typeOptions.find((option) => option.value === selectedDataObj?.type) || ''}
                      renderInput={(params) => <TextField label='Select Type' {...params} />}
                      getOptionLabel={(option) => option.label}
                    />
                    <TextField fullWidth label='Minimum Sale Required' value={selectedDataObj?.minSaleRequired} onChange={(e) => updateEditingData('minSaleRequired', e.target.value)} />
                    <TextField
                      fullWidth
                      label='Expiry Date'
                      value={selectedDataObj?.expiryDate || ''} // Corrected to reference expiryDate
                      type={'date'}
                      onChange={(e) => updateEditingData('expiryDate', e.target.value)}
                    />

                    <TextField
                      fullWidth
                      label='Amount / Percentage'
                      value={selectedDataObj?.discountAmountOrPercentage}
                      type={'number'}
                      onChange={(e) => updateEditingData('discountAmountOrPercentage', e.target.value)}
                    />
                    {isDiscount && (
                      <TextField
                        fullWidth
                        label='Maximum Discount Per Sale'
                        value={selectedDataObj?.maxDiscountPerSale}
                        type={'number'}
                        onChange={(e) => updateEditingData('maxDiscountPerSale', e.target.value)}
                      />
                    )}
                    <TextField
                      fullWidth
                      type={'number'}
                      label='Number Of Coupons Allowed to Redeem'
                      value={selectedDataObj?.currentCouponCount}
                      onChange={(e) => updateEditingData('currentCouponCount', e.target.value)}
                    />
                    <Stack direction='row' alignItems='center'>
                      <Typography variant='overline' sx={{ mr: 1.5 }}>
                        Can Same User Redeem Multiple Times
                      </Typography>
                      <Switch
                        checked={selectedDataObj?.canSameUserRedeemMulti}
                        onChange={(e) => {
                          updateEditingData('canSameUserRedeemMulti', e.target.checked);
                        }}
                      />
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                      <Typography variant='overline' sx={{ mr: 1.5 }}>
                        Allowed to specific list of customers only
                      </Typography>
                      <Switch
                        checked={selectedDataObj?.linkedCustomers}
                        onChange={(e) => {
                          updateEditingData('linkedCustomers', e.target.checked);
                        }}
                      />
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    {!isAdd && (
                      <Stack direction='row' alignItems='center'>
                        <Typography variant='overline' sx={{ mr: 1.5 }}>
                          Active Coupon
                        </Typography>
                        <Switch
                          checked={selectedDataObj?.status}
                          onChange={(e) => {
                            updateEditingData('status', e.target.checked);
                          }}
                        />
                      </Stack>
                    )}
                    <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                      {isAdd ? (isDiscount ? 'Add Discount Coupon' : 'Add Voucher Coupon') : 'Save Changes'}
                    </LoadingButton>
                    <Button
                      color='info'
                      variant='outlined'
                      size='large'
                      style={{ width: '100%' }}
                      onClick={() => {
                        manageModel(dataModel, 'add');
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                </Card>
                {selectedDataObj?.couponCustomers?.length > 0 && (
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1'>Allowed Customers {`(${selectedDataObj?.couponCustomers.length} People)`}</Typography>
                    <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                      {selectedDataObj?.couponCustomers?.map((value, index) => (
                        <Chip style={{ margin: '1px' }} key={index} label={`${value?.customerId}) ${value?.customerName}`} color={value?.redeemed === 1 ? 'success' : 'error'} />
                      ))}
                    </div>
                  </Card>
                )}
                {selectedDataObj?.couponLog?.length > 0 && (
                  <Card>
                    <Typography variant='subtitle1' sx={{ p: 3 }}>
                      Redeems Log {`(${selectedDataObj?.couponLog.length} Redeems)`}
                    </Typography>
                    <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                      <Timeline position='alternate'>
                        {selectedDataObj?.couponLog?.map((value) => (
                          <TimelineItem key={value.id}>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                              {utcMoment(value?.dateTime).format('YYYY-MM-DD HH:mm:ss')}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot>
                                <Iconify icon='eva:checkmark-circle-2-fill' />
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <Typography variant='body1' component='span'>
                                <b>{value?.customerName}</b>
                              </Typography>
                              <Typography>{fCurrency(value?.amount)}</Typography>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    </div>
                  </Card>
                )}
              </Stack>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
