import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { MobileDateRangePicker } from '@mui/lab';
import { getAllAssets } from '../../../redux/slices/assetsRedux/assetsRedux';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import { getPurchaseNotes } from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import { getForPaymentDateRange, getPreviousCashBalance } from '../../../redux/slices/financeRedux/cashFlowRedux';
import { getPaymentTypes } from '../../../redux/slices/financeRedux/paymentTypeRedux';
import { getCashFlowCategories } from '../../../redux/slices/financeRedux/cashFlowCategoriesRedux';
import PermissionRequired from '../../errorPages/permissionRequired';
import { endOfDay, format, startOfDay } from 'date-fns';
import moment from 'moment';

export default function GlobalCashbook() {
  const { themeStretch } = useSettings();

  let locationList;
  let selectedLocation;
  ({ data: locationList, selectedLocation } = useSelector((state) => state.location));

  const [cashflowList, setCashflowList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);

  const [openPicker, setOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState([startOfDay(new Date()), endOfDay(new Date())]);

  const formatDateRangeDisplay = ([start, end]) => {
    if (!start || !end) return '';
    return `${format(start, 'dd/MM/yyyy')} - ${format(end, 'dd/MM/yyyy')}`;
  };

  const { data, cashBalance } = useSelector((state) => state.cashFlow);

  let cashFlowCategories;
  ({ data: cashFlowCategories } = useSelector((state) => state.cashFlowCategories));

  useEffect(() => {
    const startDate = moment(dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    dispatch(getForPaymentDateRange(selectedLocation?.id, startDate, endDate));
    dispatch(getPreviousCashBalance(selectedLocation?.id, startDate));
  }, [selectedLocation, dateRange]);

  useEffect(() => {
    dispatch(getPaymentTypes());
    dispatch(getAllAssets());
    dispatch(getPurchaseNotes());
    dispatch(getCashFlowCategories());
  }, []);

  useEffect(() => {
    console.log(cashBalance);
  }, [cashBalance]);

  useEffect(() => {
    setCashflowList(
      data
        ?.map((value) => {
          return {
            ...value,
            linkedInfo: safeJSONParse(value?.linkedInfo),
            originalStatus: value?.status,
            linkedDocuments: safeJSONParse(value?.linkedInfo)?.cashFlowDocuments?.filter((value) => value?.url) || [],
            cashFlowLog: safeJSONParse(value?.linkedInfo)?.cashFlowLog?.filter((value) => value?.title) || [],
          };
        })
        ?.map((value) => {
          return {
            date: utcMoment(value?.paymentDate).format('YYYY-MM-DD HH:mm:ss'),
            transactionType: value?.linkedInfo?.cashFlowPaymentType?.[0]?.name,
            documentType: '-',
            companyDocumentNumber: value?.checkNo,
            issuedBy: value?.linkedInfo?.userData?.[0]?.name,
            transactionCategory: value?.linkedInfo?.cashFlowCategories?.[0]?.name,
            paymentToPaymentFrom: '',
            description: value?.description,
          };
        })
    );
  }, [data]);

  const columns = !cashflowList?.[0]
    ? []
    : Object.keys(cashflowList?.[0])
        .map((value) => {
          if (['linkedInfo', 'linkedDocuments', 'cashFlowLog'].includes(value)) {
            return {};
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  return (
    <Page title={`Global Cash Book`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Global Cash Book`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Finance',
              href: '',
            },
            { name: `Global Cash Book` },
          ]}
          action={<></>}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MobileDateRangePicker
              open={openPicker}
              onClose={() => setOpenPicker(false)}
              onOpen={() => setOpenPicker(true)}
              value={dateRange}
              onChange={(newValue) => {}}
              onAccept={(newValue) => {
                setDateRange(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <TextField
                  ref={inputRef}
                  {...inputProps}
                  InputProps={{
                    ...InputProps,
                  }}
                  fullWidth
                  label='Select Date Range'
                  value={formatDateRangeDisplay(dateRange)}
                  onClick={() => setOpenPicker(true)}
                  readOnly
                />
              )}
            />
          </Grid>
        </Grid>
        {accessVerify('CASHFLOW_VIEW') ? (
          <DataGridTable
            name={'Global Cashbook'}
            data={cashflowList}
            column={[...columns]}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            enablePinning={false}
            isRowClickable={false}
            onRowClick={(row) => {}}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}
