import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { accessVerify, capitalize, safeJSONParse } from '../../../utils/common';
import { addCashFlowDocumentType, getCashFlowDocumentType, updateCashFlowDocumentType } from '../../../redux/slices/financeRedux/cashFlowDocumentTypeRedux';
import PermissionRequired from '../../errorPages/permissionRequired';

export default function CashFlowDocumentType() {
  const dataModel = {
    id: '',
    mainCategory: '',
    name: '',
    description: '',
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [assetsList, setAssetsList] = useState([]);

  const { data, addData } = useSelector((state) => state.CashFlowDocumentType);
  const [cashFlowMainCategoryTypes, setCashFlowMainCategoryTypes] = useState([]);

  useEffect(() => {
    dispatch(getCashFlowDocumentType());
  }, []);

  useEffect(() => {
    setAssetsList(
      data?.map((value) => {
        return {
          ...value,
          locationData: safeJSONParse(value?.locationData),
          userData: safeJSONParse(value?.userData),
        };
      })
    );
    const uniqueCategories = [...new Set(data.map((item) => item.mainCategory))];

    setCashFlowMainCategoryTypes(
      uniqueCategories.map((category) => ({
        label: category,
        value: category,
      }))
    );
    setIsModelOpen(false);
  }, [data]);

  useEffect(() => {
    console.log(cashFlowMainCategoryTypes);
  }, [cashFlowMainCategoryTypes]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getCashFlowDocumentType());
    }
  }, [addData]);

  useEffect(() => {
    console.log(selectedDataObj);
    console.log(cashFlowMainCategoryTypes?.find((value) => value?.value === selectedDataObj?.mainCategory));
  }, [selectedDataObj]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !assetsList?.[0]
    ? []
    : Object.keys(assetsList?.[0])
        .map((value) => {
          if (['isVerifier', 'isIncrement', 'locationData', 'userData'].includes(value)) {
            return {};
          } else if (['mainCategory'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ row }) => `${capitalize(row?.original?.mainCategory)}`,
            };
          } else if (['name'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Sub Category',
              Cell: ({ row }) => `${capitalize(row?.original?.name)}`,
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    if (selectedDataObj.name.length === 0) {
      toast.error('Asset name is required!');
    } else if (selectedDataObj.description.length === 0) {
      toast.error('Asset description is required!');
    } else {
      dispatch(isAdd ? addCashFlowDocumentType(selectedDataObj) : updateCashFlowDocumentType(selectedDataObj));
    }
  };
  return (
    <Page title={`Cashflow Document Type`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Cashflow Document Type`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Finance',
              href: '',
            },
            { name: `Cashflow Document Type` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Cashflow Document Type
            </Button>
          }
        />
        {accessVerify('CASHFLOW_DOCUMENT_TYPE_VIEW') ? (
          <DataGridTable
            name={'Payment Types'}
            data={assetsList}
            column={columns}
            isLoading={false}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isRowClickable={true}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} CashFlow Document Types</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Main Category
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Name' value={selectedDataObj?.name} onChange={(e) => updateEditingData('name', e.target.value)} />
                    <TextField fullWidth label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                    {isAdd ? 'Add Cashflow Docuemnt' : 'Save Changes'}
                  </LoadingButton>
                  <Button
                    color='info'
                    variant='outlined'
                    size='large'
                    style={{ width: '100%' }}
                    onClick={() => {
                      manageModel(dataModel, 'add');
                    }}
                  >
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
