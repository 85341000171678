import React, { useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { accessVerify, capitalize, safeJSONParse } from '../../../../utils/common';
import { Box, Button, Card, Container, Grid, IconButton, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { addInspection, getInspection, updateInspection } from '../../../../redux/slices/hrRedux/inspection/inspectionRedux';
import { getInspectionType } from '../../../../redux/slices/hrRedux/inspection/inspectionTypeRedux';
import InspectionTemplateInformation from './templateComponents/InspectionTemplateInformation';
import AddCategoryButton from './templateComponents/addCategoryButton';
import PermissionRequired from '../../../errorPages/permissionRequired';

const sweepableDrawerStyles = {
  sx: {
    width: '98%',
    height: '97%',
    marginLeft: '1%',
    marginTop: '3%',
    borderRadius: '10px',
  },
};

export default function InspectionTemplateList() {
  const dataModel = {
    title: '',
    description: '',
    weight: '',
    inspectionTypeId: '',
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [rowSelection, setRowSelection] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [inspectionTypeList, setInspectionTypeList] = useState([]);
  const [questionPool, setQuestionPool] = useState([]);

  let inspectionData;
  let inspectionAddData;
  ({ data: inspectionData, addData: inspectionAddData } = useSelector((state) => state.inspection));

  let inspectionTypeDataList;
  ({ data: inspectionTypeDataList } = useSelector((state) => state.inspectionType));

  useEffect(() => {
    dispatch(getInspectionType());
    dispatch(getInspection());
  }, []);

  useEffect(() => {
    setInspectionTypeList(
      inspectionData?.map((value) => {
        return { ...value, questions: safeJSONParse(value?.questions) || [] };
      })
    );
    setIsModelOpen(false);
  }, [inspectionData]);

  const setQuestionPoolDataOnClick = (modelData) => {
    let tempQuestionPool = [];
    modelData?.questions?.forEach((question) => {
      console.log(question);
      const isCategoryAvailable = tempQuestionPool?.find((questionData) => questionData?.questionCategoryId === question?.categoryId);
      if (isCategoryAvailable) {
        tempQuestionPool = tempQuestionPool.map((questionData) => {
          if (questionData?.questionCategoryId === question?.categoryId) {
            return {
              ...questionData,
              questionList: [
                ...questionData?.questionList,
                {
                  questionId: question?.id,
                  question: question?.title,
                  questionDescription: question?.description,
                  weight: question?.weight,
                },
              ],
            };
          }
          return questionData;
        });
      } else {
        tempQuestionPool?.push({
          inspectionId: modelData?.id,
          questionCategoryId: question?.categoryId,
          questionCategoryTitle: question?.categoryTitle,
          questionList: [
            {
              questionId: question?.id,
              question: question?.title,
              questionDescription: question?.description,
              weight: question?.weight,
            },
          ],
        });
      }
    });
    setQuestionPool(tempQuestionPool);
  };
  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
        setQuestionPoolDataOnClick(modelData);
      }
    }
  };

  useEffect(() => {
    if (inspectionAddData) {
      dispatch(getInspectionType());
      dispatch(getInspection());
    }
  }, [inspectionAddData]);

  const updateModelData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const updateEditingData = (key, value, index, isQuestion = false, questionIndex = null) => {
    const updatedQuestionPool = [...questionPool];
    if (isQuestion) {
      updatedQuestionPool[index].questionList = updatedQuestionPool[index].questionList.map((question, idx) => {
        if (idx === questionIndex) {
          return {
            ...question,
            [key]: value,
          };
        }
        return question;
      });
      setQuestionPool(updatedQuestionPool);
    } else {
      updatedQuestionPool[index][key] = value;
      setQuestionPool(updatedQuestionPool);
    }
  };

  const columns = !inspectionTypeList?.[0]
    ? []
    : Object.keys(inspectionTypeList?.[0])
        .map((value) => {
          if (['questions'].includes(value)) {
            return {};
          } else if (value === 'weight') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return `${row.original?.weight}%`;
              },
            };
          } else if (value === 'inspectionTypeId') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return inspectionTypeDataList?.find((item) => item.id === row.original?.inspectionTypeId)?.title;
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const handleSubmit = () => {
    if (selectedDataObj.title.length === 0) {
      toast.error('Inspection name is required!');
    } else if (selectedDataObj.description.length === 0) {
      toast.error('Inspection description is required!');
    } else {
      dispatch(isAdd ? addInspection(selectedDataObj) : updateInspection({ selectedDataObj: selectedDataObj, questionPool: questionPool }));
    }
  };

  const onAddCategory = () => {
    if (selectedDataObj?.id) {
      setQuestionPool([
        ...questionPool,
        {
          inspectionId: selectedDataObj?.id,
          questionCategoryId: -1,
          questionCategoryTitle: ``,
          questionList: [],
        },
      ]);
    } else {
      toast.error('Please save the inspection first');
    }
  };
  const onAddQuestion = (questionIndex) => {
    if (selectedDataObj?.id) {
      setQuestionPool(
        [...questionPool].map((questionData, questionPoolIndex) => {
          if (questionIndex === questionPoolIndex) {
            return {
              ...questionData,
              questionList: [
                ...questionData?.questionList,
                {
                  questionId: -1,
                  question: ``,
                  questionDescription: '',
                  weight: 0,
                },
              ],
            };
          } else {
            return questionData;
          }
        })
      );
    } else {
      toast.error('Please save the inspection first');
    }
  };
  const removeQuestionFromArray = (questionBlockIndex, questionIndex) => {
    setQuestionPool((prevState) => {
      const newQuestionPool = [...prevState];
      const updatedQuestionBlock = { ...newQuestionPool[questionBlockIndex] };
      updatedQuestionBlock.questionList.splice(questionIndex, 1);
      newQuestionPool[questionBlockIndex] = updatedQuestionBlock;
      return newQuestionPool;
    });
  };

  return (
    <Page title={`Inspection List`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Inspection List`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Inspection',
              href: '',
            },
            { name: `Inspection Template List` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Inspection Template
            </Button>
          }
        />
        {accessVerify('INSPECTION_TEMPLATE_VIEW') ? (
          <DataGridTable
            name={'Inspection Template List'}
            data={inspectionTypeList}
            column={columns}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isRowClickable={true}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer anchor={'bottom'} open={isModelOpen} onOpen={() => setIsModelOpen(true)} onClose={() => setIsModelOpen(false)} PaperProps={sweepableDrawerStyles}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2 }}>
                <h1>{isAdd ? 'Add' : 'Edit'} Inspection Template</h1>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2 }}>
                <Button
                  color='info'
                  variant='outlined'
                  size='large'
                  style={{ width: '48%', marginRight: '1%' }}
                  onClick={() => {
                    manageModel(dataModel, 'add');
                  }}
                >
                  Close
                </Button>
                <LoadingButton type='submit' variant='contained' size='large' style={{ width: '48%', marginLeft: '1%' }} onClick={handleSubmit}>
                  {isAdd ? 'Add Inspection' : 'Save Changes'}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          <Stack spacing={2} sx={{ p: 2 }}>
            <InspectionTemplateInformation selectedDataObj={selectedDataObj} updateEditingData={updateModelData} inspectionTypeData={inspectionTypeDataList} />
            {!isAdd && (
              <>
                {questionPool?.map((question, index) => {
                  return (
                    <Card sx={{ p: 3 }} key={index}>
                      <Stack spacing={1} sx={{ pr: 3 }}>
                        <Typography variant='subtitle1' padding='10px 0'>
                          Question Set {index + 1}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label='Category/Subtopic Title'
                              value={question?.questionCategoryTitle}
                              onChange={(e) => updateEditingData('questionCategoryTitle', e.target.value, index, false)}
                            />
                          </Grid>
                          {question?.questionList?.map((questionData, questionIndex) => {
                            return (
                              <Grid item xs={12} md={12} key={questionIndex}>
                                <Card sx={{ p: 3 }}>
                                  <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                      <Typography variant='subtitle1' padding='10px 0'>
                                        <IconButton color='error' onClick={() => removeQuestionFromArray(index, questionIndex)}>
                                          <Iconify icon={'clarity:remove-solid'} />
                                        </IconButton>{' '}
                                        Question {questionIndex + 1}
                                      </Typography>{' '}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        fullWidth
                                        label='Question'
                                        value={questionData?.question}
                                        onChange={(e) => updateEditingData('question', e.target.value, index, true, questionIndex)}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <TextField
                                        type={'number'}
                                        fullWidth
                                        label='Weight'
                                        value={questionData?.weight}
                                        onChange={(e) => updateEditingData('weight', e.target.value, index, true, questionIndex)}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <TextField
                                        fullWidth
                                        label='Question Description'
                                        value={questionData?.questionDescription}
                                        onChange={(e) => updateEditingData('questionDescription', e.target.value, index, true, questionIndex)}
                                      />
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>
                            );
                          })}

                          <Grid item xs={12} md={12}>
                            <Button
                              color='primary'
                              variant='contained'
                              size='medium'
                              style={{ width: '100%' }}
                              onClick={() => {
                                onAddQuestion(index);
                              }}
                            >
                              Add Question
                            </Button>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Card>
                  );
                })}
                <AddCategoryButton onAddCategory={onAddCategory} />

                <LoadingButton type='submit' variant='contained' size='large' style={{ width: '25%', marginLeft: '74%', marginTop: '100px' }} onClick={handleSubmit}>
                  {isAdd ? 'Add Inspection' : 'Save Changes'}
                </LoadingButton>
              </>
            )}
          </Stack>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
