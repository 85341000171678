import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, Container, Stack, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { accessVerify, safeJSONParse } from '../../../utils/common';
import { toast } from 'react-toastify';
import PermissionRequired from '../../errorPages/permissionRequired';
import { getCustomers } from '../../../redux/slices/customerRedux/customerRedux';
import { fCurrency } from '../../../utils/formatNumber';
import SmsCounter from '../../../utils/smsCounter';

const BATCH_SIZE = 10000000;
const COST_PER_MESSAGE = 0.65;

export default function BulkSms() {
  const { themeStretch } = useSettings();
  const [customerList, setCustomerList] = useState([]);
  const { allData, addData } = useSelector((state) => state.customers);
  const [smsContent, setSmsContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [sentCount, setSentCount] = useState(0);
  const [totalBatches, setTotalBatches] = useState(0);
  const [gatewayData, setDateWayData] = useState({});
  const [smsCount, setSmsCount] = useState(0);

  useEffect(() => {
    dispatch(getCustomers());
    getPricing();
  }, []);

  useEffect(() => {
    if (allData?.length > 0) {
      setCustomerList(
        allData
          ?.filter((value) => value?.sendSms)
          ?.map((value) => {
            return {
              ...value,
              loyalty_data: safeJSONParse(value?.loyalty_data),
              pos_data: safeJSONParse(value?.pos_data),
              content: '',
            };
          })
      );
    } else {
      setCustomerList([]);
    }
  }, [allData]);

  useEffect(() => {
    dispatch(getCustomers());
  }, [addData]);

  const columns = [
    {
      accessorKey: 'customer_id',
      header: 'ID',
    },
    {
      accessorKey: 'customer_name',
      header: 'Customer Name',
    },
    {
      accessorKey: 'customer_phone',
      header: 'Phone',
    },
    {
      accessorKey: 'encordingType',
      header: 'Encoding',
    },
    {
      accessorKey: 'smsCount',
      header: 'SMS Count',
    },
    {
      accessorKey: 'content',
      header: 'SMS Content',
    },
  ];
  useEffect(() => {
    setCustomerList(
      (prevList) => {
        return prevList.map((item) => {
          return {
            ...item,
            content: smsContent.replace('--name--', item?.customer_name),
            smsCount: SmsCounter.count(smsContent.replace('--name--', item?.customer_name)).messages,
            encordingType: SmsCounter.count(smsContent.replace('--name--', item?.customer_name)).encoding,
          };
        });
      },
      [smsContent]
    );
  });
  const handleSmsChange = (event) => {
    const newContent = event.target.value;
    setSmsContent(newContent);
  };

  const getPricing = async () => {
    try {
      const body = {
        userId: '22e89e54-6155-46d5-b6ea-8ba86719d3de',
        authToken: '3b044b6c-52d2-42d0-b623-981cf53e2c25',
        senderName: 'BubbleMania',
      };

      const response = await fetch('https://api-bulksms.expergen.com/status', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
        },
        body: JSON.stringify(body),
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const data = await response.json();
      console.log(data);
      setDateWayData(data);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const sendBatchSms = async (batch) => {
    const body = {
      userId: '22e89e54-6155-46d5-b6ea-8ba86719d3de',
      authToken: '3b044b6c-52d2-42d0-b623-981cf53e2c25',
      senderName: 'BubbleMania',
      data: batch.map((value) => ({ to: value?.customer_phone, content: value?.content })),
    };

    try {
      const response = await fetch('https://api-bulksms.expergen.com/bulkSend', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
        },
        body: JSON.stringify(body),
        method: 'POST',
      });
      if (response.ok) {
        toast.success(`Batch sent successfully.`);
        setSentCount((prev) => prev + 1);
      } else {
        throw new Error('Failed to send batch');
      }
    } catch (error) {
      toast.error(`Error sending batch: ${error.message}`);
    }
  };

  useEffect(() => {
    setSmsCount(customerList?.map((value) => value?.smsCount)?.reduce((acc, value) => acc + value, 0));
  }, [smsContent, customerList]);

  const handleSendSms = async () => {
    if (smsContent?.length > 0) {
      if (smsCount * COST_PER_MESSAGE + 1000 < gatewayData?.amount) {
        setLoading(true);
        setSentCount(0);

        const batches = [];
        for (let i = 0; i < customerList.length; i += BATCH_SIZE) {
          batches.push(customerList.slice(i, i + BATCH_SIZE));
        }
        setTotalBatches(batches.length);

        for (const batch of batches) {
          await sendBatchSms(batch);
        }
        getPricing();
        setLoading(false);
      } else {
        toast.error('No Enough money to send the sms! Also we need to have additional 1000 for Shop SMS!');
      }
    } else {
      toast.error('Enter text before send!');
    }
  };

  return (
    <Page title={`Bulk Sms`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Bulk Sms`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Marketing',
              href: '',
            },
            { name: `Bulk Sms` },
          ]}
          action={<></>}
        />

        {accessVerify('CUSTOMERS_VIEW') ? (
          <>
            <Box sx={{ mt: 4 }}>
              <Card sx={{ p: 3 }}>
                <Typography variant='h5'>Send Bulk SMS (Balance: {fCurrency(gatewayData?.amount)})</Typography>
                <Typography variant='p' style={{ color: 'red' }}>
                  Cost For this SMS: {fCurrency(smsCount * COST_PER_MESSAGE)}
                </Typography>
                <br />
                <Typography variant='p'>SMS Count: {smsCount}</Typography>
                <br />
                <Typography variant='p'>Customer Count: {customerList?.length}</Typography>
                <Stack spacing={2} mt={2}>
                  <TextField label='SMS Content' multiline rows={4} value={smsContent} onChange={handleSmsChange} fullWidth />
                  <Typography variant='body2'>
                    Message Length: {SmsCounter.count(smsContent)?.length} | Sms Count: {SmsCounter.count(smsContent)?.messages} | Type: {SmsCounter.count(smsContent)?.encoding}
                  </Typography>
                  <Typography variant='body2'>You can replace name with --name--</Typography>
                  <Button variant='contained' color='primary' onClick={handleSendSms} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Send SMS'}
                  </Button>
                  {totalBatches > 0 && (
                    <Typography variant='body2'>
                      Sent {sentCount} of {totalBatches} batches
                    </Typography>
                  )}
                </Stack>
              </Card>
            </Box>
            <DataGridTable
              name={'Pos Sales'}
              data={customerList}
              column={columns}
              isLoading={false}
              rowSelection={false}
              setRowSelection={() => {}}
              enableRowActions={false}
              enablePinning={false}
              enableRowSelection={false}
              isRowClickable={true}
              renderRowActionItems={() => []}
            />
          </>
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}
